import React, { useState, useEffect } from "react";
import { Languages, SelectDatetimeReqDemoModalData } from "utils/datautils";
import Calendar from "react-calendar";
import moment from "moment-timezone";
import styles from "../styles/SelectDatetimeReqDemoModal.module.scss";

import 'react-calendar/dist/Calendar.css';

type MainProps = {
  lang: Languages;
  show: boolean;
  onSelect: (data: SelectDatetimeReqDemoModalData) => void;
  onCancel: () => void;
};

type AvailableTimeResponse = {
  start: string;
  end: string;
}

type PlaceData = 'Online' | 'Offline';

export default function SelectDatetimeReqDemoModal(props: MainProps) {
  const [date, setDate] = useState(moment().toDate());
  const [availableTimes, setAvailableTimes] = useState<AvailableTimeResponse[]>([]);
  const [selectedTime, setSelectedTime] = useState<AvailableTimeResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState<PlaceData>("Online");

  const getAvailableTimes = async (date: Date) => {
    setLoading(true);
    const resp = await fetch("/api/get-available-time", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ date: moment(date).format("YYYY-MM-DD")}),
    });
    if (!resp.ok || resp.status !== 200) {
      throw new Error();
    }
    const data = await resp.json();
    if (moment(date).day() !== 6) {
      setAvailableTimes(data.data as AvailableTimeResponse[]);
    } else {
      const filteredData = data.data.filter((d: AvailableTimeResponse) => {
        const start = moment(d.start).tz('Asia/Jakarta');
        const end = moment(d.end).tz('Asia/Jakarta');
        return start.hours() >= 10 && end.hours() <= 14;
      });
      setAvailableTimes(filteredData as AvailableTimeResponse[]);
      setPlace('Online');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (moment(date).isBefore(moment().add(1, 'days')) || moment(date).day() === 0) {
      setAvailableTimes([])
    } else {
      getAvailableTimes(date);
      setSelectedTime(null);
    }
  }, [date]);

  if (!props.show) {
    return null;
  }

  return (
    <div
      className={`${styles.modal}`}
      onClick={(click) => {
        click.stopPropagation();
        props.onCancel();
      }}
    >
      <div
        className={`${styles.request_form_modal}`}
        onClick={(click) => {
          // click.preventDefault()
          click.stopPropagation();
        }}
      >
        <div className={styles.container}>
          <div className={styles.selectDateSection}>
            <div className={styles.selectDate}>
              Pilih Tanggal dan Waktu
            </div>
            <div className={styles.calendar}>
              <Calendar
                onChange={(date) => setDate(date as Date)}
                value={date}
                minDate={moment().toDate()}
                locale="id"
              />
            </div>
          </div>
          <div className={styles.selectTimeSection}>
            <div className={styles.detailSection}>
              <div className={styles.durationTitle}>
                Durasi
              </div>
              <div className={styles.durationValue}>
                60 Menit
              </div>
            </div>
            <div className={styles.detailSection}>
              <div className={styles.placeTitle}>
                Tempat
              </div>
              <select className={styles.placeValue} onChange={(e) => setPlace(e.target.value as PlaceData)}>
                {
                  moment(date).day() !== 6 && (
                    <option selected={place === 'Offline'} value="Offline">Offline</option>
                  )
                }
                <option selected={place === 'Online'} value="Online">Online</option>
              </select>
            </div>
            <div className={styles.timeSection}>
              <div className={styles.timeTitle}>
                Pilih Waktu Ketersediaan
              </div>
              <div className={styles.timeValue}>
                {
                  loading ? (
                    <div className={styles.loading}>
                      <img src="/assets/loader-black.png"></img>
                    </div>
                  ) : (
                    availableTimes.map((data, index) => (
                      <div
                        key={index} 
                        className={styles.timeItem} 
                        onClick={() => {
                          setSelectedTime(data);
                        }}
                        style={{
                          backgroundColor: selectedTime?.start === data.start ? '#036' : '#F5F7F9',
                          color: selectedTime?.start === data.start ? '#FFF' : '#2B4156',
                        }}
                      >
                        <div className={styles.timeItemValue}>
                          {moment(data.start).tz('Asia/Jakarta').format('HH:mm z')}
                        </div>
                      </div>
                    ))
                  )
                }
                {
                  !loading && availableTimes.length === 0 && (
                    <div className={styles.noTime}>
                      Tidak ada waktu yang tersedia
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonSubmit}>
          <button
            disabled={selectedTime == null}
            onClick={() => {
              props.onSelect({
                date: moment(selectedTime?.start).toDate(),
                place,
              })
            }}
          >
            Konfirmasi
          </button>
        </div>
      </div>
    </div>
  );
}
