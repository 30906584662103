import React, {
  MouseEventHandler,
  useState,
  useRef,
  FormEvent,
  useMemo,
} from "react";
import { Languages, SelectDatetimeReqDemoModalData } from "utils/datautils";
import Cookie from "js-cookie";
import Link from "next/link";
import styles from "../styles/BookDemoModal.module.scss";
import demoStr from "@assets/strings/demo_form.json";
import moment from "moment";
import SelectDatetimeReqDemoModal from "./SelectDatetimeReqDemoModal";

type MainProps = {
  lang: Languages;
  onSave: MouseEventHandler;
  onCancel: MouseEventHandler;
};
interface RequestForm {
  name: string;
  phone: string;
  email: string;
  company: string;
  division: string;
  jobTitle: string;
  gaClientId?: string;
  source?: string;
  // meetingTime: string;
  // meetingPlace: string;
}

interface UTMData {
  utmSource?: string;
  utmCampaign?: string;
  utmContent?: string;
  utmKeywords?: string;
}

type RequestData = RequestForm & UTMData;

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function BookDemoModal(props: MainProps) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [institution, setInstitution] = useState("");
  const [division, setDivision] = useState("");
  const [job, setJob] = useState("");
  const [telephone, setTelephone] = useState("");
  const [selectedSources, setSelectedSources] = useState<string[]>([]);
  const [sourceOther, setSourceOther] = useState("");
  const [schedule, setSchedule] = useState("");
  const [scheduleModalData, setScheduleModalData] =
    useState<SelectDatetimeReqDemoModalData | null>(null);
  const [showSelectSchedule, setShowSelectSchedule] = useState(false);
  const [isError, setIsError] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  function getGAClientId() {
    if (typeof window !== undefined) {
      return Cookie.get("_ga");
    }
    return null;
  }

  function getUTMParams(): UTMData {
    let utmParams: UTMData = {};
    if (typeof window !== undefined) {
      const params = new URLSearchParams(window.location.search);
      const medium = params.get("utm_medium") as string;
      const source = params.get("utm_source") as string;
      utmParams.utmSource = medium || source ? `${medium} - ${source}` : "-";
      utmParams.utmContent = params.get("utm_content") as string;
      utmParams.utmCampaign = params.get("utm_campaign") as string;
      utmParams.utmKeywords = params.get("utm_term") as string;
    }
    return utmParams;
  }

  const onSubmit = async (event: FormEvent<EventTarget>) => {
    event.preventDefault();
    setLoading(true);
    try {
      // get ga client id
      const gaClientId = getGAClientId() as string;
      // get utm data
      const utmData = getUTMParams();
      // data source
      const dataSource = selectedSources.includes("other")
        ? selectedSources.map((item) => (item === "other" ? sourceOther : item))
        : selectedSources;
      // send data
      const requestForm: RequestData = {
        name,
        email,
        phone: telephone,
        company: institution,
        division,
        jobTitle: job,
        // meetingPlace: scheduleModalData!.place,
        // meetingTime: moment(scheduleModalData!.date).tz('Asia/Jakarta').format('DD-MM-YYYY HH:mm'),
        gaClientId,
        source: dataSource.join(`, `),
        ...utmData,
      };
      const resp = await fetch("/api/request-demo", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestForm),
      });
      if (!resp.ok || resp.status !== 200) {
        throw new Error();
      }

      // const resp2 = await fetch("/api/create-calendar-events", {
      //   method: "POST",
      //   mode: "cors",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify({
      //     dateStart: moment(scheduleModalData?.date).toISOString(),
      //     dateEnd: moment(scheduleModalData?.date).add(1, 'hour').toISOString(),
      //     userEmail: email,
      //     userName: name,
      //     companyName: institution,
      //     place: scheduleModalData?.place,
      //   }),
      // });
      // if (!resp2.ok || resp2.status !== 200) {
      //   throw new Error();
      // }
      setIsError(false);
      setIsSuccess(true);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "successRequestDemoForm",
        formName: "book-a-demo",
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const sendEvent = (form: string) => {
    const isBrowser = typeof window !== "undefined";

    isBrowser &&
      window.dataLayer &&
      window.dataLayer.push({
        event: "eventRCSWeb",
        eventNameValue: "Filling Form Demo",
        parameterName: "filling_form_demo",
        parameterValue: form,
      });
  };

  const handleCheckSourceChange = (e: any) => {
    const { value } = e.target;

    setSelectedSources((prev) => {
      if (prev.includes(value)) {
        return prev.filter((source) => source !== value);
      }

      return [...prev, value];
    });
  };

  if (showSelectSchedule) {
    return (
      <SelectDatetimeReqDemoModal
        lang={props.lang}
        show={showSelectSchedule}
        onCancel={() => setShowSelectSchedule(false)}
        onSelect={(data) => {
          setSchedule(
            `${moment(data.date)
              .locale("id")
              .tz("Asia/Jakarta")
              .format("D MMMM YYYY, HH:mm z")}, ${
              demoStr.duration[props.lang]
            } ${demoStr["60min"][props.lang]}, ${demoStr.via[props.lang]} ${
              data.place
            }`
          );
          setScheduleModalData(data);
          setShowSelectSchedule(false);
        }}
      />
    );
  }

  if (isSuccess) {
    return (
      <div
        className={`${styles.modal}`}
        onClick={(click) => {
          click.stopPropagation();
          props.onCancel(click);
        }}
      >
        <div
          className={`${styles.request_form_modal} ${styles.success}`}
          onClick={(click) => {
            // click.preventDefault()
            click.stopPropagation();
          }}
        >
          <div className={styles.success_container}>
            <img src="/assets/thank-you.png"></img>
            <p>{demoStr.thanks[props.lang]}</p>

            <Link href="/" passHref>
              <button
                className={styles.solid}
                onClick={(click) => props.onCancel(click)}
              >
                {demoStr.backToMainPage[props.lang]}
              </button>
            </Link>
            <button
              className={styles.clear}
              onClick={(click) => props.onCancel(click)}
            >
              {demoStr.close[props.lang]}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${styles.modal}`}
      onClick={(click) => {
        click.stopPropagation();
        props.onCancel(click);
      }}
    >
      <div
        className={`${styles.request_form_modal} ${
          submitted && isError ? styles.error : ""
        } ${isSuccess ? styles.success : ""}`}
        onClick={(click) => {
          // click.preventDefault()
          click.stopPropagation();
        }}
      >
        <div className={styles.header}>
          <div>{demoStr?.title[props.lang]}</div>
          <img
            id="closeModal"
            onClick={props.onCancel}
            src="/assets/close_black.png"
          ></img>
        </div>
        <div>{demoStr?.subtitle[props.lang]}</div>
        <div className={isSuccess ? styles.success_notif : "none"}>
          {demoStr.thanks[props.lang]}
        </div>
        <div style={{ display: isSuccess ? "none" : "block" }}>
          <form
            ref={form}
            className={`${styles.form} ${submitted ? styles.submitted : ""}`}
            action="/pages/api/request-demo"
            onSubmit={onSubmit}
          >
            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="email"
                type="email"
                placeholder={demoStr.email[props.lang]}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                onBlur={() => sendEvent("Email")}
                pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>
                  {email.length === 0
                    ? demoStr.err_empty[props.lang]
                    : demoStr.err_email[props.lang]}
                </div>
              </div>
            </div>

            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="name"
                type="text"
                placeholder={demoStr.name[props.lang]}
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
                onBlur={() => sendEvent("Nama Lengkap")}
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>{demoStr.err_empty[props.lang]}</div>
              </div>
            </div>

            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="institution"
                type="text"
                placeholder={demoStr.institution[props.lang]}
                value={institution}
                onChange={(e) => setInstitution(e.currentTarget.value)}
                onBlur={() => sendEvent("Perusahaan / Institusi")}
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>{demoStr.err_empty[props.lang]}</div>
              </div>
            </div>

            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="division"
                type="text"
                placeholder={demoStr.division[props.lang]}
                value={division}
                onChange={(e) => setDivision(e.currentTarget.value)}
                onBlur={() => sendEvent("Divisi")}
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>{demoStr.err_empty[props.lang]}</div>
              </div>
            </div>

            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="job"
                type="text"
                placeholder={demoStr.job[props.lang]}
                value={job}
                onChange={(e) => setJob(e.currentTarget.value)}
                onBlur={() => sendEvent("Jabatan")}
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>{demoStr.err_empty[props.lang]}</div>
              </div>
            </div>

            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="telephone"
                type="tel"
                placeholder={demoStr.phone[props.lang]}
                value={telephone}
                onChange={(e) =>
                  setTelephone(e.currentTarget.value.replace(/\D/g, ""))
                }
                onBlur={() => sendEvent("No. Handphone")}
                pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$"
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>
                  {isError && (!telephone || telephone === "")
                    ? demoStr.err_empty[props.lang]
                    : demoStr.err_phone[props.lang]}
                </div>
              </div>
            </div>

            <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <div style={{ marginTop: "2rem", display: "block" }}>
                <label
                  style={{
                    marginBottom: "1rem",
                    fontWeight: "600",
                    fontSize: "1rem",
                    display: "inline-block",
                    color:
                      submitted && selectedSources.length < 1
                        ? "#F8403A"
                        : "inherit",
                  }}
                >
                  {demoStr.source_opt_placeholder[props.lang]}
                </label>

                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div style={{ width: "60%" }}>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-google"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_google[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_google[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-google"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_google[props.lang]}
                      </label>
                    </div>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-website"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_website[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_website[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-website"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_website[props.lang]}
                      </label>
                    </div>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-colleague"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_colleague[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_colleague[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-colleague"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_colleague[props.lang]}
                      </label>
                    </div>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-facebook"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_facebook[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_facebook[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-facebook"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_facebook[props.lang]}
                      </label>
                    </div>
                  </div>
                  <div style={{ width: "40%" }}>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-instagram"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_instagram[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_instagram[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-instagram"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_instagram[props.lang]}
                      </label>
                    </div>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-twitter"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_twitter[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_twitter[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-twitter"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_twitter[props.lang]}
                      </label>
                    </div>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-linkedin"
                        type="checkbox"
                        name="source"
                        value={demoStr.source_opt_linkedin[props.lang]}
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes(
                          demoStr.source_opt_linkedin[props.lang]
                        )}
                      />
                      <label
                        htmlFor="opt-linkedin"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_linkedin[props.lang]}
                      </label>
                    </div>
                    <div style={{ marginBottom: ".5rem", display: "block" }}>
                      <input
                        id="opt-other"
                        type="checkbox"
                        name="source"
                        value="other"
                        onChange={handleCheckSourceChange}
                        checked={selectedSources.includes("other")}
                      />
                      <label
                        htmlFor="opt-other"
                        style={{ marginLeft: ".25rem" }}
                      >
                        {demoStr.source_opt_other[props.lang]}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {submitted && selectedSources.length < 1 && (
                <div
                  style={{
                    display: "flex",
                    gap: ".5rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="/assets/alert.png"
                    style={{ display: "inline-block" }}
                  />
                  <div
                    style={{
                      display: "inline-block",
                      color: "#F8403A",
                      fontSize: "10px",
                    }}
                  >
                    {demoStr.err_empty[props.lang]}
                  </div>
                </div>
              )}
            </div>

            {selectedSources.includes("other") && (
              <div
                className={`${styles.input_group} ${
                  isError ? styles.error : ""
                }`}
              >
                <input
                  name="otherSource"
                  type="text"
                  placeholder={demoStr.source_opt_other[props.lang]}
                  value={sourceOther}
                  onChange={(e) => setSourceOther(e.currentTarget.value)}
                  required
                  disabled={loading ? true : false}
                />

                <div className={isError ? styles.error : "none"}>
                  <img src="/assets/alert.png" />
                  <div>
                    {isError &&
                      (!sourceOther || sourceOther === "") &&
                      demoStr.err_empty[props.lang]}
                  </div>
                </div>
              </div>
            )}

            {/* <div
              className={`${styles.input_group} ${isError ? styles.error : ""}`}
            >
              <input
                name="schedule"
                type="text"
                placeholder={demoStr.selectSchedule[props.lang]}
                value={schedule}
                onFocus={() => setShowSelectSchedule(true)}
                required
                disabled={loading ? true : false}
              />
              <div className={isError ? styles.error : "none"}>
                <img src="/assets/alert.png"></img>
                <div>{demoStr.err_empty[props.lang]}</div>
              </div>
            </div> */}

            <button
              className={styles.solid}
              type="submit"
              onClick={() => setSubmitted(true)}
              disabled={loading ? true : false}
            >
              {loading ? (
                <img src="/assets/loader.png"></img>
              ) : (
                demoStr.submit[props.lang]
              )}
            </button>
          </form>
        </div>
        <button className={styles.clear} onClick={props.onCancel}>
          {isSuccess ? demoStr.back[props.lang] : demoStr.cancel[props.lang]}
        </button>
      </div>
    </div>
  );
}
